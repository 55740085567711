import alpine from "../assets/partner/alpine.png";
import alturaNft from "../assets/partner/altura-nft.png";
import atari from "../assets/partner/atari.png";
import minutes20 from "../assets/partner/20minutes.png";
import blckParis from "../assets/partner/blck-paris.png";
import cnews from "../assets/partner/cnews.png";
import coinbase from "../assets/partner/coinbase.png";
import cointribute from "../assets/partner/cointribute.png";
import consensys from "../assets/partner/consensys.png";
import crossmint from "../assets/partner/crossmint.png";
import doodles from "../assets/partner/doodles.png";
import elixir from "../assets/partner/elixir.png";
import exclusible from "../assets/partner/exclusible.png";
import essec from "../assets/partner/essec.png";
import ethereum from "../assets/partner/ethereum.png";
import forbes from "../assets/partner/forbes.png";
import franceDigital from "../assets/partner/france-digital.png";
import immutablex from "../assets/partner/immutablex.png";
import looksrare from "../assets/partner/looksrare.png";
import magiceden from "../assets/partner/magiceden.png";
import metalinq from "../assets/partner/metalinq.png";
import neoTokyo from "../assets/partner/neo-tokyo.png";
import newedge from "../assets/partner/newedge.png";
import nfc from "../assets/partner/nfc.png";
import nftFactory from "../assets/partner/nft-factory.png";
import nftLondon from "../assets/partner/nft-london.png";
import opensea from "../assets/partner/opensea.png";
import poweder from "../assets/partner/poweder.png";
import stationF from "../assets/partner/station-f.png";
import ubisoft from "../assets/partner/ubisoft.png";
import unreal from "../assets/partner/unreal.png";
import x2y2 from "../assets/partner/x2y2.png";

// Weapons

import weaponKryo from "../assets/resources/weaponKryo.png";
import weaponLuxion from "../assets/resources/weaponLuxion.png";
import weaponNaya from "../assets/resources/weaponNaya.png";
import resourcesRock from "../assets/resources/ressources20.png";
import resourcesOthers from "../assets/resources/ressources21.png";
import extractors from "../assets/resources/extractors.png";
import armoury from "../assets/resources/armoury.png";
import engineeringBay from "../assets/resources/engineeringBay.png";
import pharmacy from "../assets/resources/pharmacy.png";

// planets
import planetLuxion from "../assets/lands/planet-luxion.png";
import planetKryo from "../assets/lands/planet-kryo.png";
import planetNaya from "../assets/lands/planet-naya.png";
import landKryo from "../assets/lands/land-kryo.png";
import landNaya from "../assets/lands/land-naya.png";
import landLuxion from "../assets/lands/land-luxion.png";

import bgLandsKryo from "../assets/backgrounds/ourlands-kryo.png";
import bgLandsKryoMobile from "../assets/backgrounds/mobile/ourlands-kryo.jpg";
import bgLandsLuxion from "../assets/backgrounds/ourlands-luxion.jpg";
import bgLandsLuxionMobile from "../assets/backgrounds/mobile/ourlands-luxion.png";
import bgLandsNaya from "../assets/backgrounds/ourlands-naya.jpg";
import bgLandsNayaMobile from "../assets/backgrounds/mobile/ourlands-naya.png";

// ecosystem
import gatemap from "../assets/ecosystem/gatemap.jpg";
import headquarter from "../assets/ecosystem/dashboard.jpg";
import roquelite from "../assets/ecosystem/roguelite2.jpg";

import gatemapVideo from "../assets/ecosystem/gatemap.mp4";
import roqueliteVideo from "../assets/ecosystem/roquelite2.mp4";
import headquartersVideo from "../assets/ecosystem/headquarters.mp4";

// gaming
import earnGaming from "../assets/gaming/earn.jpg";
import surviveGaming from "../assets/gaming/survive.jpg";
import exploreGaming from "../assets/gaming/explore.jpg";
import interactGaming from "../assets/gaming/interact.png";
import collaborativeGaming from "../assets/gaming/collaborative.jpg";
import marketplaceGaming from "../assets/gaming/marketplace.jpg";

// wallets
import metamaskWallet from "../assets/wallets/metamask.png";
import walletConnectWallet from "../assets/wallets/walletConnect.png";
import coinbaseWallet from "../assets/wallets/coinbase.png";
import ledgerWallet from "../assets/wallets/ledger.png";
import trustwallet from "../assets/wallets/trustwallet.png";
import walletUnknow1 from "../assets/wallets/walletunknow1.png";
import walletUnknow2 from "../assets/wallets/walletunknow2.png";
import walletUnknow3 from "../assets/wallets/walletunknow3.png";
import walletUnknow4 from "../assets/wallets/walletunknow4.png";
import { appUrl } from "./text";

export const imgPartners = [
  {
    key: 1,
    img: alpine,
    alt: "Alpine",
  },
  {
    key: 2,
    img: alturaNft,
    alt: "Altura Nft",
  },
  {
    key: 3,
    img: atari,
    alt: "Atari",
  },
  {
    key: 4,
    img: minutes20,
    alt: "20minutes",
  },
  {
    key: 5,
    img: blckParis,
    alt: "Blck Paris",
  },
  {
    key: 6,
    img: cnews,
    alt: "Cnews",
  },
  {
    key: 7,
    img: coinbase,
    alt: "Coinbase",
  },
  {
    key: 8,
    img: cointribute,
    alt: "Cointribute",
  },
  {
    key: 9,
    img: consensys,
    alt: "Consensys",
  },
  {
    key: 10,
    img: crossmint,
    alt: "Crossmint",
  },
  {
    key: 11,
    img: doodles,
    alt: "Doodles",
  },
  {
    key: 12,
    img: elixir,
    alt: "Elixir",
  },
  {
    key: 13,
    img: exclusible,
    alt: "Exclusible",
  },
  {
    key: 14,
    img: essec,
    alt: "Essec",
  },
  {
    key: 15,
    img: ethereum,
    alt: "Ethereum",
  },
  {
    key: 16,
    img: forbes,
    alt: "Forbes",
  },
  {
    key: 17,
    img: franceDigital,
    alt: "France Digital",
  },
  {
    key: 18,
    img: immutablex,
    alt: "ImmutableX",
  },
  {
    key: 19,
    img: looksrare,
    alt: "Looksrare",
  },
  {
    key: 20,
    img: magiceden,
    alt: "Magic Eden",
  },
  {
    key: 21,
    img: metalinq,
    alt: "Metalinq",
  },
  {
    key: 22,
    img: neoTokyo,
    alt: "Neo Tokyo",
  },
  {
    key: 23,
    img: newedge,
    alt: "Newedge",
  },
  {
    key: 24,
    img: nfc,
    alt: "NFC",
  },
  {
    key: 25,
    img: nftFactory,
    alt: "NFT Factory",
  },
  {
    key: 26,
    img: nftLondon,
    alt: "NFT London",
  },
  {
    key: 27,
    img: opensea,
    alt: "Opensea",
  },
  {
    key: 28,
    img: poweder,
    alt: "Poweder",
  },
  {
    key: 29,
    img: stationF,
    alt: "StationF",
  },
  {
    key: 30,
    img: ubisoft,
    alt: "Ubisoft",
  },
  {
    key: 31,
    img: unreal,
    alt: "Unreal",
  },
  {
    key: 32,
    img: x2y2,
    alt: "X2Y2",
  },
];

export const imgWeaponsResources = [
  {
    key: 1,
    step: 1,
    name: "Raw Materials",
    img: resourcesRock,
    id: "resourcesRock",
    defaultSelected: true,
    title: "Raw materials",
    details:
      "Alkar, Steelvar and Electrium have specific properties that can be used to craft and upgrade in-game items.",
  },
  {
    key: 2,
    step: 2,
    name: "Berg Splitter",
    img: weaponKryo,
    id: "Berg Splitter",
    defaultSelected: false,
    title: "Berg Splitter",
    details: "Fully automatic weapon that will never overheat.",
  },
  {
    key: 3,
    step: 3,
    name: "Lava Gun",
    img: weaponLuxion,
    id: "Lava Gun",
    defaultSelected: false,
    title: "Lava Gun",
    details: "Shoots hot, fiery magma blasts that melt the opposition.",
  },
  {
    key: 4,
    step: 4,
    name: "Bone Defractor",
    img: weaponNaya,
    id: "Bone Defractor",
    defaultSelected: false,
    title: "Bone Defractor",
    details: "This precision rifle will come in handy if rushed from behind.",
  },
  {
    key: 5,
    step: 5,
    name: "Armoury",
    img: armoury,
    id: "armoury",
    defaultSelected: false,
    title: "Armoury",
    details: (
      <span>
        Used to craft and upgrade{" "}
        <span style={{ fontWeight: 600 }}>weapons</span> and{" "}
        <span style={{ fontWeight: 600 }}>equipment</span> that players can use
        in-game.
      </span>
    ),
  },
  {
    key: 6,
    step: 6,
    name: "Engineering Bay",
    img: engineeringBay,
    id: "Engineering Bay",
    defaultSelected: false,
    title: "Engineering Bay",
    details:
      "Used to craft and upgrade utility items that open up yield opportunities such as Extraction Bots and Combat Robots.",
  },
  {
    key: 7,
    step: 7,
    name: "Pharmacy",
    img: pharmacy,
    id: "Pharmacy",
    defaultSelected: false,
    title: "Pharmacy",
    details: "Gives players the possibility to craft medical items",
  },
  {
    key: 8,
    step: 8,
    name: "Extractor",
    img: extractors,
    id: "Extractor",
    defaultSelected: false,
    title: "Extractor",
    details:
      "Alkar, Steelvar and Electrium extractors collect raw materials from a Land passively.",
  },
  {
    key: 9,
    step: 1,
    name: "Raw Materials",
    img: resourcesRock,
    id: "resourcesRock",
    defaultSelected: true,
    title: "Raw materials",
    details:
      "Alkar, Steelvar and Electrium have specific properties that can be used to craft and upgrade in-game items.",
  },
  {
    key: 10,
    step: 2,
    name: "Berg Splitter",
    img: weaponKryo,
    id: "Berg Splitter",
    defaultSelected: false,
    title: "Berg Splitter",
    details: "Fully automatic weapon that will never overheat.",
  },
  {
    key: 11,
    step: 3,
    name: "Lava Gun",
    img: weaponLuxion,
    id: "Lava Gun",
    defaultSelected: false,
    title: "Lava Gun",
    details: "Shoots hot, fiery magma blasts that melt the opposition.",
  },
  {
    key: 12,
    step: 4,
    name: "Bone Defractor",
    img: weaponNaya,
    id: "Bone Defractor",
    defaultSelected: false,
    title: "Bone Defractor",
    details: "This precision rifle will come in handy if rushed from behind.",
  },
  {
    key: 13,
    step: 5,
    name: "Armoury",
    img: armoury,
    id: "armoury",
    defaultSelected: false,
    title: "Armoury",
    details: (
      <span>
        Used to craft and upgrade{" "}
        <span style={{ fontWeight: 600 }}>weapons</span> and{" "}
        <span style={{ fontWeight: 600 }}>equipment</span> that players can use
        in-game.
      </span>
    ),
  },
  {
    key: 14,
    step: 6,
    name: "Engineering Bay",
    img: engineeringBay,
    id: "Engineering Bay",
    defaultSelected: false,
    title: "Engineering Bay",
    details:
      "Used to craft and upgrade utility items that open up yield opportunities such as Extraction Bots and Combat Robots.",
  },
  {
    key: 15,
    step: 7,
    name: "Pharmacy",
    img: pharmacy,
    id: "Pharmacy",
    defaultSelected: false,
    title: "Pharmacy",
    details: "Gives players the possibility to craft medical items",
  },
  {
    key: 16,
    step: 8,
    name: "Extractor",
    img: extractors,
    id: "Extractor",
    defaultSelected: false,
    title: "Extractor",
    details:
      "Alkar, Steelvar and Electrium extractors collect raw materials from a Land passively.",
  },
  {
    key: 17,
    step: 9,
    name: "Robot Extractor",
    img: resourcesOthers,
    id: "Robot Extractor",
    defaultSelected: false,
    title: "Robot Extractor",
    details: "Will extract resources at a faster pace if placed on a Land.",
  },
];

export const imgLands = [
  {
    key: 0,
    step: 1,
    fakeStep: 1,
    name: "planet luxion",
    imgPlanet: planetLuxion,
    imgLand: landLuxion,
    imgBg: bgLandsLuxion,
    imgBgMobile: bgLandsLuxionMobile,
    id: "planet-luxion",
    defaultSelected: false,
    style: { boxShadow: "0px 0px 200px 20px rgba(199, 57, 37, .75)" },
    title: (
      <span
        style={{
          textTransform: "uppercase",
          fontSize: "2.2rem",
          color: "white",
          fontWeight: 500,
          fontFamily: "Akira",
        }}
      >
        Luxio<span style={{ color: "rgb(186, 23, 0)" }}>n</span>
      </span>
    ),
    detailsBox:
      "The magmatic wasteland’s volcanoes produce greenhouse gasses that maintain temperatures over 360 degrees celsius.",
  },
  {
    key: 1,
    step: 2,
    fakeStep: 2,
    name: "planet kryo",
    imgPlanet: planetKryo,
    imgLand: landKryo,
    imgBg: bgLandsKryo,
    imgBgMobile: bgLandsKryoMobile,
    id: "planet-kryo",
    defaultSelected: true,
    style: { boxShadow: "0px 0px 200px 20px rgba(38, 99, 153, 1)" },
    title: (
      <span
        style={{
          textTransform: "uppercase",
          fontSize: "2.2rem",
          color: "white",
          fontWeight: 500,
          fontFamily: "Akira",
        }}
      >
        Kry<span style={{ color: "rgb(15, 190, 255)" }}>o</span>
      </span>
    ),
    detailsBox:
      "Snowy Kryo’s mountain range is the home of mineral-rich caves and the forgotten laboratories.",
  },
  {
    key: 2,
    step: 3,
    fakeStep: 3,
    name: "planet naya",
    imgPlanet: planetNaya,
    imgLand: landNaya,
    imgBg: bgLandsNaya,
    imgBgMobile: bgLandsNayaMobile,
    id: "planet-naya",
    defaultSelected: false,
    style: { boxShadow: "0px 0px 200px 20px rgba(199, 134, 73, .75)" },
    title: (
      <span
        style={{
          textTransform: "uppercase",
          fontSize: "2.2rem",
          color: "white",
          fontWeight: 500,
          fontFamily: "Akira",
        }}
      >
        Nay<span style={{ color: "rgb(197, 123, 19)" }}>a</span>
      </span>
    ),
    detailsBox:
      "Naya’s dunes and arid plains are inhabited by mysterious spirits that bring life to the desert.",
  },
];

export const imgEcosystem = [
  {
    key: 0,
    selected: false,
    title: "Road to Genesis",
    subTitle: "",
    description: (
      <span>
        Free-to-play <span style={{ fontWeight: 600 }}>open world game</span> in
        which players can{" "}
        <span style={{ fontWeight: 600 }}>
          explore the Oxya Origin universe
        </span>
        , interact with the environment,{" "}
        <span style={{ fontWeight: 600 }}>complete quests</span> and play
        different game modes where they will try to{" "}
        <span style={{ fontWeight: 600 }}>survive in the rogue dungeons</span>{" "}
        as they{" "}
        <span style={{ fontWeight: 600 }}>
          uncover the story's secrets and collect rewards along the way
        </span>
        .
      </span>
    ),
    img: roquelite,
    video: roqueliteVideo,
    textBtnOne: "Opensea Avatars",
    onClickBtnOne: () => {
      window.open(
        "https://opensea.io/fr/collection/oxyaoriginproject",
        "_blank"
      );
    },
    textBtnTwo: "Play Pre-Alpha",
    onClickBtnTwo: () => {
      window.open(appUrl);
    },
    disabledBtnOne: false,
    disabledBtnTwo: false,
  },
  {
    key: 1,
    selected: false,
    title: "HeadQuarters",
    subTitle: "",
    description: (
      <span>
        The <span style={{ fontWeight: 600 }}>HeadQuarters</span> is a the{" "}
        <span style={{ fontWeight: 600 }}>
          heart of the Oxya Origin ecosystem
        </span>
        . The{" "}
        <span style={{ fontWeight: 600 }}>innovative dashboard interface</span>{" "}
        was designed as a{" "}
        <span style={{ fontWeight: 600 }}>
          bridge between the different game modes
        </span>
        . It will allow players to manage their Oxya Origin Lands and in-game
        assets as well as{" "}
        <span style={{ fontWeight: 600 }}>
          conduct exchanges on the marketplace without gas fees
        </span>
        .
      </span>
    ),
    img: headquarter,
    video: headquartersVideo,
    textBtnOne: "Buy Lands",
    onClickBtnOne: () => {
      window.open(
        "https://opensea.io/fr/collection/gatesofoxya-lands",
        "_blank"
      );
    },
    textBtnTwo: "HeadQuarters",
    onClickBtnTwo: () => {},
    disabledBtnOne: false,
    disabledBtnTwo: true,
  },
  {
    key: 2,
    selected: true,
    title: "Gatemap",
    subTitle: "",
    description:
      "Providing a bird’s eye view of the three planets and the Lands that constitute them, the GateMap is the intergalactic traveler and real estate mogul’s most precious tool.\n\n" +
      "Players can explore all three planets, visit and customise their Lands and delve deeper than ever into the rich universe of the Gates of Oxya.",
    img: gatemap,
    video: gatemapVideo,
    textBtnOne: "Buy Lands",
    onClickBtnOne: () => {
      window.open(
        "https://opensea.io/fr/collection/gatesofoxya-lands",
        "_blank"
      );
    },
    textBtnTwo: "Visit Gatemap",
    onClickBtnTwo: () => {
      window.open("https://gatemap.oxyaorigin.com", "_blank");
    },
    disabledBtnOne: false,
    disabledBtnTwo: false,
  },
];

export const imgCarouselGaming = [
  {
    key: 0,
    alt: "explore the open world",
    img: exploreGaming,
    title: (
      <span>
        <span style={{ fontWeight: 600, fontFamily: "Akira" }}>
          {"EXPLORE\n"}
        </span>
        the open world
      </span>
    ),
  },
  {
    key: 1,
    alt: "survive in the rogue dungeons",
    img: surviveGaming,
    defaultSelected: true,
    title: (
      <span>
        <span style={{ fontWeight: 600, fontFamily: "Akira" }}>
          {"SURVIVE\n"}
        </span>
        in the rogue dungeons
      </span>
    ),
  },
  {
    key: 2,
    alt: "earn exclusive rewards",
    img: earnGaming,
    title: (
      <span>
        <span style={{ fontWeight: 600, fontFamily: "Akira" }}>{"EARN\n"}</span>
        exclusive rewards
      </span>
    ),
  },
  {
    key: 3,
    alt: "trade you way to the top",
    img: marketplaceGaming,
    title: (
      <span>
        <span style={{ fontWeight: 600, fontFamily: "Akira" }}>
          {"TRADE\n"}
        </span>{" "}
        your way to the top
      </span>
    ),
  },
  {
    key: 4,
    alt: "collaborate with other players",
    img: collaborativeGaming,
    title: (
      <span>
        <span style={{ fontWeight: 600, fontFamily: "Akira" }}>
          {"COLLABORATE\n"}
        </span>{" "}
        with other players
      </span>
    ),
  },
  {
    key: 5,
    alt: "interact with partners",
    img: interactGaming,
    title: (
      <span>
        <span style={{ fontWeight: 600, fontFamily: "Akira" }}>
          {"INTERACT\n"}
        </span>{" "}
        with partners
      </span>
    ),
  },
];

export const imgWallets = [
  {
    key: 0,
    alt: "metamask wallet",
    img: metamaskWallet,
  },
  {
    key: 1,
    alt: "walletConnect wallet",
    img: walletConnectWallet,
  },
  {
    key: 2,
    alt: "coinbase wallet",
    img: coinbaseWallet,
  },
  {
    key: 3,
    alt: "ledger wallet",
    img: ledgerWallet,
  },
  {
    key: 4,
    alt: "trust wallet",
    img: trustwallet,
  },
  {
    key: 5,
    alt: "Unknow wallet1",
    img: walletUnknow1,
  },
  {
    key: 6,
    alt: "Unknow wallet2",
    img: walletUnknow2,
  },
  {
    key: 7,
    alt: "Unknow wallet3",
    img: walletUnknow3,
  },
  {
    key: 8,
    alt: "Unknow wallet4",
    img: walletUnknow4,
  },
];

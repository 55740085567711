import { createTheme } from "@material-ui/core";

const theme = createTheme({
  typography: {
    body1: {
      fontSize: 12,
      fontFamily: "Montserrat, sans-serif",
      margin: 0,
    },
    body2: {
      fontSize: 12,
      fontFamily: "Montserrat, sans-serif",
      margin: 0,
    },

    /* Suppress error "You are using the typography
     * variant caption which will be restyled in the next major release
     */
    useNextVariants: true,
    fontFamily: [
      "Montserrat",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "white",
  },
  palette: {
    primary: {
      light: "rgb(15, 190, 255)",
      main: "rgb(7, 16, 32)",
      dark: "rgba(15, 190, 255, 0.078)",
      contrastText: "#fff",
    },
    text: {
      primary: "#ffffff",
      secondary: "#00649E",
      blueDark: "#29405F",
      grey: "#394D67",
    },
    secondary: {
      light: "#FFF0CE",
      main: "#FFB100",
      dark: "#A37713",
      contrastText: "#fff",
    },
    grey: {
      800: "#555555",
    },
    gradient: {
      main: "linear-gradient( 90deg, rgb(10,29,62) 0%, rgb(15,46,80) 100%",
      btn: "linear-gradient(90deg, #FFCD6294 0%, #FFCD6294 0%, #73662994 100%)",
      btnBlue: "linear-gradient(90deg, #1C88ED 0%, #00D8FF 100%)",
    },
    background: {
      default: "#fefefe",
      blueDark: "rgb(7, 12, 25)",
    },
  },
  // sm down === <= sm
  // sm up === >= sm
  breakpoints: {
    values: {
      xs: 400, // Smartphone
      sm: 768, // Ipad 768
      md: 1024, // Small PC
      lg: 1280, // Medium PC
      xl: 1920, // Large PC
    },
  },
  overrides: {
    MuiTypography: {
      p: {
        color: "#ffffff",
      },
      h1: {
        color: "#ffffff",
        fontSize: "1.8rem",
      },
      h2: {
        color: "#ffffff",
        fontSize: "1.6rem",
      },
      h3: {
        color: "#ffffff",
        fontSize: "1.2rem",
      },
    },
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        background: "none",
        borderRadius: "2rem",
        color: "white",
      },
      outlined: {
        borderRadius: "2rem",
        color: "white",
        border: "solid 2px #fcfcfc",
      },
      label: {
        fontFamily: "Montserrat,Roboto,Helvetica, arial, sans-serif",
        fontWeight: "bold",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        textAlign: "center",
        textTransform: "none",
        fontSize: "1.6rem",
      },
      startIcon: {
        display: "inline",
        position: "relative",
        top: "4px",
      },
      iconSizeMedium: {
        label: {
          fontSize: "initial",
        },
      },
    },
    MuiBadge: {
      badge: {
        fontSize: "1rem",
      },
    },
    MuiDivider: {
      root: {
        borderRadius: "6px",
        backgroundImage: "linear-gradient(to bottom, #fc7756, #fc5564)",
        height: "5px",
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        border: "solid 1px rgba(15, 190, 255, 0.3)",
        borderRadius: "2rem",
        fontFamily: "Montserrat, sans-serif",
        fontWeight: "500",
        fontSize: "1.4rem",
        height: "5rem",
        color: "#bbb7b7",
      },
      input: {
        fontSize: "1.4rem",
        transform: "translate(3px, -4px) scale(1)",
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: "1.4rem",
        fontWeight: "500",
        color: "#bbb7b7",
      },
    },
    MuiTextField: {
      root: {
        "& input": {
          fontSize: "1.4rem",
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        transform: "translate(19px, 16px) scale(1)",
      },
    },
  },
});

export default theme;

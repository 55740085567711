import React, { useEffect } from "react";

import { Box, Typography, makeStyles } from "@material-ui/core";
import teamBorder from "../../assets/team/team-border.png";

import Menu from "../organisms/Menu/menu";
import NavBar from "../organisms/NavBar/nav-bar";
import ModalCenterTeam from "../molecules/ModalCenterTeam/modal-center-team";
import AccordionBox from "../atoms/AccordionBox/accordionBox";
import { teams } from "../../constants/text";

export default function About() {
  const classes = useStyle({});
  const [openModalMember, setOpenModalMember] = React.useState(false);
  const [memberSelected, setMemberSelected] = React.useState({});

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleCloseModal = () => {
    setOpenModalMember(false);
  };

  const handleOpenModalMember = (member) => () => {
    setMemberSelected(member);
    setOpenModalMember(true);
  };

  return (
    <div className={classes.root}>
      {openModalMember && (
        <ModalCenterTeam
          open={openModalMember}
          onClose={handleCloseModal}
          member={memberSelected}
        />
      )}
      <section className={classes.section}>
        <Menu className={classes.menu} canBeVisible noWait />
        <Box className={classes.wrapperPage}>
          <NavBar />
          <Box className={classes.containerContent}>
            <Typography variant="h1">Team</Typography>
            <Box className={classes.content}>
              <Box className={classes.wrapperTeams}>
                {teams.map((team) => (
                  <AccordionBox
                    key={`team-${team.key}`}
                    className={classes.wrapperAccordionTeam}
                    classNameDetails={classes.detailsAccordionTeam}
                    classNamePanel={classes.pannelTeam}
                    summary={team.title}
                    defaultExpanded={team.defaultExpanded}
                  >
                    {team.members.map((member) => (
                      <button
                        key={member.name}
                        type="button"
                        className={classes.teamItem}
                        onClick={
                          member.bio ? handleOpenModalMember(member) : () => {}
                        }
                        style={{ cursor: member.bio ? "pointer" : "initial" }}
                      >
                        <Box className={classes.wrapperImgTeam}>
                          <img
                            alt={member.name}
                            src={member.pic}
                            width={172}
                            height={172}
                            loading="lazy"
                          />
                        </Box>
                        <Typography
                          variant="body1"
                          className={classes.nameTeam}
                        >
                          {member.name}
                        </Typography>
                        <Typography
                          variant="body1"
                          className={classes.roleTeam}
                        >
                          {member.role}
                        </Typography>
                        {member.bio && (
                          <Typography
                            variant="body1"
                            className={classes.readMoreTeam}
                          >
                            read more +
                          </Typography>
                        )}
                      </button>
                    ))}
                  </AccordionBox>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </section>
    </div>
  );
}

const useStyle = makeStyles(({ breakpoints, palette }) => ({
  root: {
    overflowX: "hidden",
    fontSize: "1.4rem",
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    background: "rgb(7, 12, 25)",
  },
  section: {
    position: "relative",
    width: "100%",
    minHeight: "100vh",
  },
  menu: {
    maxWidth: "10rem",
    minWidth: "10rem",
    padding: "6rem 0",
    position: "fixed",
    height: "100%",
    zIndex: 2,
    background: "transparent",
    transform: "translate(0)",
    [breakpoints.down("xs")]: {
      display: "none",
    },
  },
  wrapperPage: {
    padding: "3rem",
    [breakpoints.up("sm")]: {
      paddingLeft: "13rem",
    },
    width: "100%",
    height: "100%",
  },
  containerContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    minHeight: "calc(100vh - 17rem)",
    "& h1": {
      marginTop: "3rem",
      textAlign: "center",
      width: "100%",
      fontFamily: "Akira",
      fontWeight: 600,
      textShadow: "0px 3px 9px rgba(0, 0, 0, 0.58)",
      lineHeight: 1.2,
      textTransform: "uppercase",
      whiteSpace: "pre-wrap",
      fontSize: "7rem",
      color: "white",
      [breakpoints.down("lg")]: {
        fontSize: "6rem",
      },
      [breakpoints.down("md")]: {
        fontSize: "4.8rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "3rem",
        textAlign: "center",
      },
    },
    "@media (max-height: 800px)": {
      marginTop: "4rem",
    },
  },
  content: {
    marginTop: "3rem",
  },

  wrapperTeams: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    paddingRight: "2rem",
    paddingLeft: "2rem",
    gap: "4rem",
  },
  wrapperAccordionTeam: {
    boxShadow: "none",
    border: "none",
    background: "transparent",
    borderRadius: "none",
  },
  detailsAccordionTeam: {
    margin: "auto",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "2rem",
    overflow: "auto",
  },
  pannelTeam: {
    background: "transparent",
  },
  teamItem: {
    background: "none",
    border: "none",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    transition: "0.2s all ease-in-out",
    "&:hover": {
      transform: "scale(1.05,1.05)",
    },
    [breakpoints.down("lg")]: {
      width: "20%",
    },
    [breakpoints.down("md")]: {
      width: "25%",
    },
    [breakpoints.down("sm")]: {
      width: "30%",
    },
    [breakpoints.down("xs")]: {
      width: "45%",
    },
  },
  wrapperImgTeam: {
    backgroundImage: `url(${teamBorder})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    padding: "1rem",
    width: "192px",
    height: "192px",
    [breakpoints.down("sm")]: {
      width: "128px",
      height: "128px",
    },
    [breakpoints.down("xs")]: {
      width: "128px",
      height: "128px",
    },
    "& img": {
      borderRadius: "100%",
      padding: "1rem",
      width: "100%",
      height: "100%",
      [breakpoints.down("sm")]: {
        padding: "0.5rem",
      },
    },
  },
  nameTeam: {
    color: "white",
    fontSize: "1.6rem",
    lineHeight: "2.4rem",
    marginTop: "1rem",
    fontWeight: 700,
    textTransform: "uppercase",
    [breakpoints.down("sm")]: {
      fontSize: "1.4rem",
      lineHeight: "1.6rem",
    },
    [breakpoints.down("xs")]: {
      fontSize: "1.2rem",
    },
  },
  roleTeam: {
    color: "white",
    fontSize: "1.4rem",
    fontWeight: 500,
    lineHeight: "2rem",
    [breakpoints.down("sm")]: {
      fontSize: "1.2rem",
    },
  },
  readMoreTeam: {
    color: palette.primary.light,
    textDecorationLine: "underline",
    textUnderlineOffset: "4px",
    textTransform: "uppercase",
    fontSize: "1.2rem",
    lineHeight: "2rem",
  },
}));

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { CssBaseline, MuiThemeProvider } from "@material-ui/core";
import { Route, BrowserRouter } from "react-router-dom";

import AppRoutes from "./routes";
import theme from "./themes/muiTheme";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Route component={AppRoutes} />
      </MuiThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

import React from "react";
import Proptypes from "prop-types";
import BlueBox from "../../atoms/BlueBox/blue-box";
import ButtonWithIcon from "../../molecules/ButtonWithIcon/button-with-icon";
import { makeStyles, Typography, Box } from "@material-ui/core";

import resourcesRock from "../../../assets/resources/ressources20.png";
import buildings from "../../../assets/resources/buildings.png";

import iconOpensea from "../../../assets/social/opensea.svg";
import { sizeMobileForOurLands } from "../../../constants/size";

export default function BoxDetailsPlanet({ className, landSelected }) {
  const classes = useStyle({});

  const handleClickBuyLands = () => {
    window.open("https://opensea.io/fr/collection/gatesofoxya-lands", "_blank");
  };

  return (
    <BlueBox className={(classes.root, className)}>
      <Typography variant="body1">Planet</Typography>
      <Typography variant="h3">{landSelected.title}</Typography>
      <Typography variant="body1">Size: 1x1</Typography>
      <Box className={classes.wrapperContentDetails}>
        <Box className={classes.containerDetailsBoxMobile}>
          <Typography variant="body1" className={classes.textDetails}>
            {landSelected.detailsBox}
          </Typography>
          <ButtonWithIcon
            className={classes.btnBuyOpenseaResponsive}
            hrefIcon={iconOpensea}
            text="Buy lands"
            onClick={handleClickBuyLands}
          />
        </Box>
        <Box className={classes.containerResBuild}>
          <Box
            display="flex"
            flexDirection="column"
            className={classes.contentResBuild}
          >
            <Typography variant="body1">Resources available:</Typography>
            <img
              src={resourcesRock}
              alt="resources rock"
              style={{ width: "10rem" }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            className={classes.contentResBuild}
          >
            <Typography variant="body1">Buildings:</Typography>
            <img src={buildings} alt="buildings" style={{ width: "10rem" }} />
          </Box>
        </Box>
      </Box>
      <ButtonWithIcon
        hrefIcon={iconOpensea}
        text="Buy lands"
        className={classes.btnBuyOpensea}
        onClick={handleClickBuyLands}
      />
    </BlueBox>
  );
}

BoxDetailsPlanet.propTypes = {
  className: Proptypes.string,
  landSelected: Proptypes.object,
};

BoxDetailsPlanet.defaultProps = {};

const useStyle = makeStyles(({ palette, breakpoints }) => ({
  root: {},
  btnBuyOpensea: {
    margin: "2rem auto",
    padding: "0 1rem",
    [breakpoints.down("sm")]: {
      display: "none",
    },
    [breakpoints.down(sizeMobileForOurLands)]: {
      display: "flex",
    },
  },
  wrapperContentDetails: {
    [breakpoints.down("sm")]: {
      display: "flex",
      margin: "2rem 0",
      width: "100%",
      justifyContent: "space-between",
    },
    [breakpoints.down(sizeMobileForOurLands)]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItem: "center",
    },
  },
  containerDetailsBoxMobile: {
    display: "none",
    [breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      gap: "2rem",
      width: "50%",
    },
    [breakpoints.down(sizeMobileForOurLands)]: {
      width: "100%",
      textAlign: "center",
      padding: "0 1rem",
    },
  },
  textDetails: {
    [breakpoints.down("sm")]: {
      fontSize: "1.6rem",
    },
  },
  btnBuyOpenseaResponsive: {
    [breakpoints.down("sm")]: {
      width: "18rem",
      padding: 0,
    },
    [breakpoints.down(sizeMobileForOurLands)]: {
      display: "none",
    },
  },
  containerResBuild: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2rem 0",
    [breakpoints.down("sm")]: {
      margin: 0,
      flexDirection: "column-reverse",
      gap: "2rem",
      width: "30%",
    },
    [breakpoints.down(sizeMobileForOurLands)]: {
      width: "100%",
      marginTop: "2rem",
    },
  },
  contentResBuild: {
    [breakpoints.down(sizeMobileForOurLands)]: {
      margin: "auto",
      textAlign: "center",
      alignItem: "center",
      justifyContent: "center",
    },
  },
}));

import React from "react";
import cx from "classnames";
import { makeStyles } from "@material-ui/core";
import Proptypes from "prop-types";

export default function BlueBox({ children, className, onMouseLeave }) {
  const classes = useStyle();
  return (
    <div
      onMouseLeave={onMouseLeave}
      className={cx(classes.container, className)}
    >
      {children}
    </div>
  );
}

BlueBox.propTypes = {
  children: Proptypes.node,
  className: Proptypes.string,
  onMouseLeave: Proptypes.func,
};

const useStyle = makeStyles(({ palette }) => ({
  container: {
    height: "100%",
    // border: `1px solid rgba(15, 190, 255, 0.3)`,
    border: `1px solid #18A0FB`,
    background: palette.primary.main,
    boxShadow: `0 0 20px #27c6f72b`,
    borderRadius: "2rem",
  },
}));

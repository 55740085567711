import React from "react";
import cx from "classnames";
import Proptypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import AccordionBox from "../../atoms/AccordionBox/accordionBox";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import bgGamemodeAccordion from "../../../assets/backgrounds/gamemode-accordion.jpg";

import { Box, Typography } from "@material-ui/core";
import ButtonPerso from "../../atoms/ButtonPerso/button-perso";
import { HashLink } from "react-router-hash-link";

export default function AccordionGameMode({
  className,
  title,
  defaultExpanded,
  textBtnActionOne,
  textBtnActionTwo,
  isDisabledOne,
  isDisabledTwo,
  onClickOne,
  onClickTwo,
  hashLinkOne,
  hashLinkTwo,
  textBtnPlay,
  hrefBackgroundBtn,
  details,
  onClickVideo,
}) {
  const classes = useStyle({ hrefBackgroundBtn });
  return (
    <AccordionBox
      className={cx(classes.root, className)}
      classNamePanel={classes.panel}
      summary={title}
      classNameDetails={classes.detailsAccordion}
      defaultExpanded={defaultExpanded}
      variantSummary="h3"
    >
      <Box display="flex" flexDirection="column">
        <Box
          className={classes.wrapper}
          display="flex"
          flex={1}
          gridColumnGap="2rem"
          gridRowGap="2rem"
        >
          {textBtnPlay && (
            <button className={classes.btnVideo} onClick={onClickVideo}>
              <Box
                display="flex"
                flexDirection="column"
                gridColumnGap="2rem"
                gridRowGap="2rem"
              >
                <Box className={classes.wrapperIconPlay}>
                  <PlayArrowIcon
                    fontSize="large"
                    color="white"
                    className={classes.iconPlay}
                  />
                </Box>
                <Typography variant="body1" className={classes.textBtnPlay}>
                  {textBtnPlay}
                </Typography>
              </Box>
            </button>
          )}
          <Typography variant="body2" className={classes.textDetailsAccordion}>
            {details}
          </Typography>
        </Box>
        <Box display="flex" flex={1} className={classes.wrapperBtn}>
          <ButtonPerso
            className={classes.btnIcon}
            disabled={isDisabledOne}
            onClick={onClickOne}
          >
            {hashLinkOne ? (
              <HashLink to={hashLinkOne} smooth className={classes.hashLink}>
                {textBtnActionOne}
              </HashLink>
            ) : (
              textBtnActionOne
            )}
          </ButtonPerso>
          <ButtonPerso
            className={classes.btnIcon}
            disabled={isDisabledTwo}
            onClick={onClickTwo}
          >
            {hashLinkTwo ? (
              <HashLink to={hashLinkTwo} smooth className={classes.hashLink}>
                {textBtnActionTwo}
              </HashLink>
            ) : (
              textBtnActionTwo
            )}
          </ButtonPerso>
        </Box>
      </Box>
    </AccordionBox>
  );
}

AccordionGameMode.propTypes = {
  className: Proptypes.string,
  title: Proptypes.oneOfType([Proptypes.string, Proptypes.node]),
  textBtnActionOne: Proptypes.string,
  hrefIconBtnActionOne: Proptypes.node,
  textBtnActionTwo: Proptypes.string,
  hrefIconBtnActionTwo: Proptypes.node,
  details: Proptypes.string,
  hrefBackgroundBtn: Proptypes.node,
  defaultExpanded: Proptypes.bool,
  isDisabledOne: Proptypes.bool,
  isDisabledTwo: Proptypes.bool,
  onClickOne: Proptypes.func,
  onClickTwo: Proptypes.func,
  hashLinkOne: Proptypes.string,
  hashLinkTwo: Proptypes.string,
  onClickVideo: Proptypes.func,
};

AccordionGameMode.defaultProps = {
  hrefBackgroundBtn: bgGamemodeAccordion,
  defaultExpanded: false,
  isDisabledOne: false,
  isDisabledTwo: false,
};

const useStyle = makeStyles(({ palette, breakpoints }) => ({
  root: {
    background: "rgba(7, 16, 32, 0.88)",
  },
  panel: {
    background: "transparent",
  },
  wrapper: {
    [breakpoints.down(600)]: {
      flexDirection: "column",
    },
  },
  wrapperBtn: {
    [breakpoints.down(600)]: {
      flexDirection: "column",
      marginTop: "2rem",
      gap: "2rem",
    },
  },
  detailsAccordion: {
    overflow: "hidden",
    padding: "3rem",
  },
  btnVideo: {
    flex: 1,
    border: "none",
    minHeight: "15rem",
    borderRadius: "2rem",
    backgroundImage: (props) => `url('${props.hrefBackgroundBtn}')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  wrapperIconPlay: {
    background: "rgba(15, 190, 255, 0.078)",
    padding: "1rem",
    borderRadius: "50%",
    border: `2px solid ${palette.primary.light}`,
    width: "max-content",
    margin: "auto",
    display: "flex",
    cursor: "pointer",
    "& svg": {
      transition: "0.2s all ease-in-out",
    },
    transition: "0.2s all ease-in-out",
    "&:hover": {
      "& svg": {
        opacity: 0.8,
      },
    },
  },
  textDetailsAccordion: {
    fontSize: "1.6rem",
    whiteSpace: "pre-wrap",
    flex: 1,
    textAlign: "justify",
  },
  btnIcon: {
    boxShadow: "none",
    padding: "1rem",
    margin: "2rem auto",
    width: "230px",
    [breakpoints.down(600)]: {
      margin: "0 auto",
    },
  },
  iconPlay: {
    fontSize: "4rem",
    filter:
      "brightness(0) invert(1) drop-shadow( 1px 1px 10px rgb(255, 255, 255))",
  },
  textBtnPlay: {
    fontWeight: "bold",
    fontSize: "1.8rem",
    color: "white",
    textShadow: "2px 2px 2px rgba(0, 0, 0, 1)",
  },
  hashLink: {
    textDecoration: "none",
    color: "white",
  },
}));

import React from "react";
import cx from "classnames";
import Proptypes from "prop-types";
import { CircularProgress, makeStyles } from "@material-ui/core";

export default function ButtonPerso({
  children,
  className,
  noBoxShadow,
  disabled,
  onClick,
  onMouseEnter,
  onMouseLeave,
  shrink,
  isLoading,
  withBorder,
  id,
  noUppercase,
}) {
  const classes = useStyle({
    withBorder,
    noBoxShadow,
  });
  return (
    <button
      className={cx(
        classes.root,
        disabled ? classes.disabled : null,
        shrink ? classes.shrink : null,
        noUppercase ? classes.noUppercase : null,
        className
      )}
      onClick={!disabled && !isLoading ? onClick : null}
      type="button"
      id={id}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {isLoading && (
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress
            style={{
              color: "white",
            }}
            size={25}
          />
        </div>
      )}
      <span className={isLoading ? classes.hidden : null}>{children}</span>
    </button>
  );
}

ButtonPerso.propTypes = {
  className: Proptypes.string,
  children: Proptypes.node,
  onClick: Proptypes.func,
  onMouseEnter: Proptypes.func,
  onMouseLeave: Proptypes.func,
  disabled: Proptypes.bool,
  shrink: Proptypes.bool,
  isLoading: Proptypes.bool,
  withBorder: Proptypes.bool,
  id: Proptypes.string,
  noUppercase: Proptypes.bool,
  noBoxShadow: Proptypes.bool,
};

ButtonPerso.defaultProps = {
  children: null,
  onClick: () => {},
  onMouseEnter: () => {},
  onMouseLeave: () => {},
  disabled: false,
  shrink: false,
  isLoading: false,
  withBorder: true,
  id: "",
  noUppercase: false,
  noBoxShadow: false,
};

const useStyle = makeStyles(({ palette, breakpoints }) => ({
  root: {
    padding: "0 1rem",
    position: "relative",
    display: "flex",
    minHeight: "4rem",
    boxShadow: (props) => (props.noBoxShadow ? "none" : "0 0 30px #27c6f74d"),
    color: "white",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "Montserrat, sans-serif",
    width: "auto",
    textAlign: "center",
    textDecoration: "none",
    textTransform: "uppercase",
    cursor: "pointer",
    background: palette.primary.dark,
    border: (props) =>
      props.withBorder ? `1px solid ${palette.primary.light}` : "none",
    borderRadius: "5rem",
    fontSize: "1.8rem",
    lineHeight: 1.2,
    fontWeight: 700,
    [breakpoints.down("sm")]: {
      fontSize: "1.6rem",
    },
    "& span": {
      transition: "0.2s all ease-in-out",
    },
    "&:hover": {
      "& span": {
        color: palette.primary.light,
      },
      "& a": {
        color: palette.primary.light,
      },
    },
    "& a": {
      transition: "0.2s all ease-in-out",
    },
  },
  noUppercase: {
    textTransform: "initial",
  },
  hidden: {
    color: () => "transparent !important",
  },

  disabled: {
    cursor: "auto",
    color: `${palette.text.grey} !important`,
    borderColor: `${palette.text.grey} !important`,
    background: "#0F1822 !important",
    "&:hover": {
      opacity: "1",
      color: `${palette.text.grey} !important`,
      "& p": {
        color: `${palette.text.grey} !important`,
      },
      "& span": {
        color: `${palette.text.grey} !important`,
      },
    },
  },
  shrink: {
    width: "auto",
  },
}));

import iconHome from "../assets/icons/home.svg";
import iconGaming from "../assets/icons/gaming.svg";
import iconEcosystem from "../assets/icons/ecosystem.svg";
import iconAbout from "../assets/icons/team.svg";

export const menu = [
  {
    key: 1,
    title: "Home",
    icon: iconHome,
    to: "/",
    alt: "home",
    widthIcon: 41,
    heightIcon: 36,
  },
  {
    key: 2,
    title: "Gaming",
    to: "/gaming",
    icon: iconGaming,
    alt: "gaming",
    widthIcon: 43,
    heightIcon: 43,
  },
  {
    key: 3,
    title: "Ecosystem",
    to: "/ecosystem",
    alt: "ecosystem",
    icon: iconEcosystem,
    widthIcon: 42,
    heightIcon: 38,
  },
  {
    key: 4,
    title: "About",
    to: "/about",
    alt: "about",
    icon: iconAbout,
    widthIcon: 42,
    heightIcon: 38,
  },
];

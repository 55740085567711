import React, { useEffect } from "react";
import cx from "classnames";
import Proptypes from "prop-types";
import { menu } from "../../../constants/menu";
import { Typography, makeStyles, Box } from "@material-ui/core";
import { timeToAppear } from "../../../constants/animation";
import ButtonOxyaToken from "../../molecules/ButtonOxyaToken/button-oxya-token";
import { useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export default function Menu({ className, listMenu, canBeVisible, noWait }) {
  const classes = useStyle();
  const history = useHistory();

  useEffect(() => {
    if (canBeVisible) {
      const timeOut = setTimeout(() => {
        const menuComponent = document.getElementById("menu");
        menuComponent.classList.add(classes.translate0);
      }, [timeToAppear]); //TODO CHANGE BY TmeToAppear

      return () => {
        clearTimeout(timeOut);
      };
    }
  }, [canBeVisible]);
  return (
    <Box id="menu" className={cx(classes.root, className)}>
      <Box
        className={cx(classes.menu)}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        {listMenu.map((elem) => {
          const isHome =
            elem.title === "Home" && history.location.pathname === "/";
          const isCurrentPath = history.location.pathname === elem.to;

          return (
            <HashLink smooth to={elem.to} key={`menu-${elem.key}`}>
              <button
                className={cx(
                  classes.itemMenu,
                  isCurrentPath || isHome ? classes.selectMenu : ""
                )}
                type="button"
              >
                <img
                  width={elem.widthIcon}
                  height={elem.heightIcon}
                  src={elem.icon}
                  alt={elem.alt}
                />
                <Typography variant="body1">{elem.title}</Typography>
              </button>
            </HashLink>
          );
        })}
      </Box>
      <ButtonOxyaToken />
    </Box>
  );
}

const useStyle = makeStyles(({ palette }) => ({
  root: {
    display: "flex",
    transform: "translateX(-110px)",
    transition: "0.2s all ease-in-out",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRight: "1px solid rgba(82, 156, 219, 0.22)",
  },
  translate0: {
    transform: "translate(0)",
  },
  menu: {
    gap: "5rem",
    "& a": {
      width: "7rem",
      textDecoration: "none",
    },
  },
  itemMenu: {
    width: "100%",
    display: "flex",
    cursor: "pointer",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    background: "transparent",
    border: "none",
    transition: "0.2s opacity ease-in-out",
    "& img": {
      filter:
        "brightness(0) invert(1) drop-shadow( 1px 1px 10px rgb(255, 255, 255))",
    },
    "& p": {
      textTransform: "uppercase",
      fontSize: "1.2rem",
      letterSpacing: "1px",
      color: "white",
    },
    "&:hover": {
      opacity: 0.8,
    },
  },
  selectMenu: {
    filter:
      "brightness(0) invert(1) drop-shadow( 1px 1px 10px rgb(15, 190, 255))",
    "&::after": {
      content: "''",
      position: "absolute",
      left: "81px",
      width: "7px",
      height: "48px",
      background: "white",
      borderRadius: "3rem",
    },
  },
}));

Menu.propTypes = {
  className: Proptypes.string,
  listMenu: Proptypes.arrayOf(
    Proptypes.shape({
      key: Proptypes.number,
      title: Proptypes.string,
      icon: Proptypes.node,
      alt: Proptypes.string,
      widthIcon: Proptypes.number,
      heightIcon: Proptypes.number,
    })
  ),
  canBeVisible: Proptypes.bool,
  noWait: Proptypes.bool,
};

Menu.defaultProps = {
  listMenu: menu,
  canBeVisible: false,
  noWait: false,
};

import React, { useEffect } from "react";

import { Box, Typography, makeStyles } from "@material-ui/core";
import Menu from "../organisms/Menu/menu";
import NavBar from "../organisms/NavBar/nav-bar";

export default function PrivacyPolicy() {
  const classes = useStyle({});

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className={classes.root}>
      <section className={classes.section}>
        <Menu className={classes.menu} canBeVisible noWait />
        <Box className={classes.wrapperPage}>
          <NavBar />
          <Box className={classes.containerContent}>
            <Typography variant="h1">Privacy Policy</Typography>
            <Box className={classes.wrapperText}>
              <h2>1. Data Collection</h2>
              <ul>
                <li>
                  <strong>Types of Data Collected</strong>: We may collect
                  various types of information, including but not limited to
                  personal information (such as name, email address, and contact
                  details), transaction information, and usage data related to
                  your interactions with our website and services.
                </li>
                <li>
                  <strong>Methods of Data Collection</strong>: Data is collected
                  through various means such as when you provide it directly to
                  us, through automated technologies (like cookies and web
                  beacons), and from third-party sources.
                </li>
              </ul>
              <h2>2. Use of Data</h2>
              <ul>
                <li>
                  <strong>Purpose of Data Collection</strong>: The data we
                  collect is used to provide, maintain, and improve our
                  services, to manage your purchases and accounts, to
                  communicate with you, to personalize your experience, and for
                  security and compliance purposes.
                </li>
                <li>
                  <strong>Marketing and Communication</strong>: With your
                  consent, we may use your data to send you promotional
                  materials, updates, and other communications. You can opt-out
                  of these communications at any time.
                </li>
              </ul>
              <h2>3. Data Retention</h2>
              <ul>
                <li>
                  <strong>Retention Period</strong>: We retain your personal
                  data only for as long as necessary to fulfill the purposes for
                  which it was collected, including for the purposes of
                  satisfying any legal, accounting, or reporting requirements.
                  The retention period varies depending on the type of data and
                  the specific requirements.
                </li>
                <li>
                  <strong>Data Deletion</strong>: Upon request, we will delete
                  your personal data unless we are required to retain it by law
                  or for legitimate business purposes.
                </li>
              </ul>
              <h2>4. Data Sharing</h2>
              <ul>
                <li>
                  <strong>Third-Party Service Providers</strong>: We may share
                  your data with third-party service providers who perform
                  services on our behalf, such as payment processors, analytics
                  providers, and marketing partners. These providers are
                  obligated to protect your data and use it only for the
                  purposes we specify.
                </li>
                <li>
                  <strong>Legal Requirements</strong>: We may disclose your data
                  if required by law or in response to valid requests by public
                  authorities (e.g., a court or a government agency).
                </li>
              </ul>
              <h2>5. Data Security</h2>
              <ul>
                <li>
                  <strong>Protection Measures</strong>: We implement appropriate
                  technical and organizational measures to protect your personal
                  data from unauthorized access, use, alteration, and
                  destruction. These measures include encryption, firewalls, and
                  secure server facilities.
                </li>
                <li>
                  <strong>User Responsibilities</strong>: You are responsible
                  for maintaining the security of your account credentials and
                  for any activities or actions under your account.
                </li>
              </ul>
              <h2>6. User Rights</h2>
              <ul>
                <li>
                  <strong>Access and Correction</strong>: You have the right to
                  access and update your personal data at any time. If you
                  believe that any information we are holding on you is
                  incorrect or incomplete, please contact us to correct it.
                </li>
                <li>
                  <strong>Data Portability and Erasure</strong>: You have the
                  right to request the transfer of your data to another service
                  provider and to request the deletion of your data, subject to
                  certain conditions.
                </li>
              </ul>
              <h2>7. Children's Privacy</h2>
              <ul>
                <li>
                  <strong>Age Restrictions</strong>: Our services are not
                  intended for children under the age of 16. We do not knowingly
                  collect personal data from children under 16. If we become
                  aware that a child under 16 has provided us with personal
                  data, we will take steps to delete such data.
                </li>
              </ul>
              <h2>8. Policy Updates</h2>
              <ul>
                <li>
                  <strong>Changes to This Policy</strong>: We may update this
                  Privacy Policy from time to time to reflect changes in our
                  practices or for other operational, legal, or regulatory
                  reasons. We will notify you of any significant changes by
                  posting the new policy on our website and updating the
                  effective date.
                </li>
              </ul>
              <h2>9. Contact Information</h2>
              <ul>
                <li>
                  <strong>Inquiries and Requests</strong>: If you have any
                  questions about this Privacy Policy or wish to exercise your
                  rights, please contact us at contact@oxyaorigin.com. By using
                  our website and services, you agree to the collection, use,
                  and retention of your data as described in this Privacy
                  Policy.
                </li>
              </ul>
              <br />
              <p>
                By using our website and services, you agree to the collection,
                use, and retention of your data as described in this Privacy
                Policy.
              </p>
            </Box>
          </Box>
        </Box>
      </section>
    </div>
  );
}

const useStyle = makeStyles(({ breakpoints }) => ({
  root: {
    overflowX: "hidden",
    fontSize: "1.4rem",
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    background: "rgb(7, 12, 25)",
  },
  section: {
    position: "relative",
    width: "100%",
    minHeight: "100vh",
  },
  menu: {
    maxWidth: "10rem",
    minWidth: "10rem",
    padding: "6rem 0",
    position: "fixed",
    height: "100%",
    zIndex: 2,
    background: "transparent",
    transform: "translate(0)",
    [breakpoints.down("xs")]: {
      display: "none",
    },
  },
  wrapperPage: {
    padding: "3rem",
    [breakpoints.up("sm")]: {
      paddingLeft: "13rem",
    },
    width: "100%",
    height: "100%",
  },
  containerContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    minHeight: "calc(100vh - 17rem)",
    "& h1": {
      marginTop: "3rem",
      textAlign: "center",
      width: "100%",
      fontFamily: "Akira",
      fontWeight: 600,
      textShadow: "0px 3px 9px rgba(0, 0, 0, 0.58)",
      lineHeight: 1.2,
      textTransform: "uppercase",
      whiteSpace: "pre-wrap",
      fontSize: "7rem",
      color: "white",
      [breakpoints.down("lg")]: {
        fontSize: "6rem",
      },
      [breakpoints.down("md")]: {
        fontSize: "4.8rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "3rem",
        textAlign: "center",
      },
    },
    "@media (max-height: 800px)": {
      marginTop: "4rem",
    },
  },
  wrapperText: {
    marginTop: "3rem",
    "& p": {
      textAlign: "justify",
    },
  },
}));

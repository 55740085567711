import React, { useEffect } from "react";

import { Box, Typography, makeStyles } from "@material-ui/core";
import Menu from "../organisms/Menu/menu";
import NavBar from "../organisms/NavBar/nav-bar";

export default function Terms() {
  const classes = useStyle({});

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className={classes.root}>
      <section className={classes.section}>
        <Menu className={classes.menu} canBeVisible noWait />
        <Box className={classes.wrapperPage}>
          <NavBar />
          <Box className={classes.containerContent}>
            <Typography variant="h1">Terms &amp; conditions</Typography>
            <Box className={classes.wrapperText}>
              <p>
                1) This website belongs to Oxya Origin, the terms “Oxya
                Origin”,” we”, “us” or “our”, depending on the context, refers
                to Oxya Origin. By using our website, you agree to the following
                terms and conditions and this after every update of those same
                terms and conditions. By purchasing a product from us you also
                agree to the following terms and conditions. By agreeing to the
                following terms and conditions you agree being at least the
                legal age in your country or province of residence.
              </p>
              <br />
              <p>
                2) We have the right to change, replace or update those terms
                and conditions without prior notice, and this at any time. You
                have the responsibility to review those terms and conditions and
                to stay informed of any update.
              </p>
              <br />
              <p>
                3)Oxya Origin is a randomly generated collection of digital
                artworks stocked on the Ethereum network or also called NFT.
                Buyers of those same NFT are only responsible for the safety and
                the management of their own and private assets (Ethereum wallets
                and more). The buyers have the responsibility of validating all
                transactions related to all contracts generated by this website
                prior and after purchase.
              </p>
              <br />
              <p>
                4) All connected services provided by us including this website
                are provided in their actual situation. We don't assure any
                warranty of any kind. Those services are provided “as is” and
                “as provided”. As written previously in the terms and
                conditions, the use of the website constitute your agreement to
                those same terms and conditions, and that you are accepting the
                responsibility for all transactions that includes an Oxya Origin
                NFT
              </p>
              <br />
              <p>
                5) The Service Provider does not represent or warrant that:
                Access to or use of the Website will be uninterrupted, timely,
                secure or error-free; Usage data provided by the Services or on
                the Website will always be accurate; The Services are free of
                malwares or other harmful components.
              </p>
              <br />
              <p>
                6) You may not use any of our products and/or belongings for
                legal or unauthorized purpose.
              </p>
              <br />

              <p>
                7) You may not use our products, if using it or buying it
                violates any laws in your jurisdiction.
              </p>
              <br />

              <p>
                8) Visiting any website where our products resell (NFT
                marketplace) you also agree to their terms and conditions.
              </p>
              <br />

              <p>
                9) This website serves for informational purposes and binding
                contracts between Oxya Origin and the purchasers of NFT Oxya
                Origin.
              </p>
              <br />

              <p>
                10) As the Oxya Origin smart contract runs on the Ethereum
                network it is bound to their terms and conditions, and any
                transactions can’t be reversed, undo or restored.
              </p>
              <br />

              <p>
                11) The user/ buyer confirms that he fully understands the NFT
                markets including the risks of it, expenses and feed (also
                called gas fees). The user acknowledged the risk of using and
                interacting with a smart contract, and the risks of buying and
                holding an NFT, this includes ( but not limited to ) the risk of
                losing the access to the NFT in case of the loss of the wallet
                key, custodial error or purchaser error, risk of mining or
                blockchain attacks, risk of hacking and security weaknesses,
                risk of unfavorable regulatory intervention in one or more
                jurisdictions, risks related to token taxation, risk of personal
                information disclosure, risk of uninsured losses, unanticipated
                risks, and volatility risks.
              </p>
              <br />

              <p>
                12) The user confirms that he is aware that the purchase of NFT
                will be with Ethereum cryptocurrency and we have no control
                regarding gas fees collecting by the miners.
              </p>
              <br />

              <p>
                13) By purchasing an Oxya Origin NFT you agree that you only buy
                the artwork of the NFT its properties and its ownership, stocked
                as ERC-721 tokens on the Ethereum blockchain.
              </p>
              <br />

              <p>
                14) By purchasing an Oxya Origin NFT the ownership of it is
                transfer to you on the Ethereum blockchain with all the
                combination of traits and its number, this is ruled by our smart
                contract that you can see on our website and by the Ethereum
                blockchain terms. We have no responsibility and can’t act on the
                ownership of our nft.
              </p>
              <br />

              <p>
                15) This ownership of the Oxya Origin NFT is for and only for
                the NFT that you randomly buy though the minting process
                (interaction with the smart contract).
              </p>
              <br />

              <p>
                16) This ownership allows use copy and display of the Oxya
                Origin artwork that you possess and for any derivative artwork
                or extensions that you could create or use, limited by the
                following usage: - Through any marketplace that allow the
                purchase and selling of Oxya Origin NFT as long as the same
                marketplace verify that you are the sole owner of the NFT. -
                Personal use (for commercial use check the next section of the
                terms and conditions) This ownership license applies only to the
                extent that the NFT owner still own the NFT which the ownership
                refers to.
              </p>
              <br />

              <p>
                17) Actual owner of an Oxya Origin NFT, Oxya Origin grants you
                an unlimited and worldwide license, to use and display the
                purchased NFT or the creation of any derived artwork, the only
                exception is to produce and sell any physical merchandise and
                product (such as clothing posters figurines, but not only).
              </p>
              <br />

              <p>
                18) We reserve the right to use any character or derivative
                artwork or media including video, anime and more to support the
                Oxya Origin community and project.
              </p>
              <br />

              <p>
                19) IT IS FORBIDDEN TO USE ANY OF OUR ARTWORK (EVEN IF YOU
                POSSES THE NFT) IN CONNECTION WITH IMAGES OF HATRED VIOLENCE OR
                ANY INAPPROPRIATE BEHAVIOR.
              </p>
              <br />

              <p>
                20) The license provided to you through the above terms only
                applies to the extent that you continue to own the NFT
                previously bought, if you decide to sell trade exchange giveaway
                donate or dispose in your NFT in any other way and for any
                reason, the granted license will at the same moment expire and
                you’ll have no right regarding the artwork or the NFT.
              </p>
              <br />

              <p>
                21) Other than the NFT bought you have no right to use any of
                the Oxya Origin trademark or/and intellectual property or/and
                the associated logos, all of those are expressly reserved in the
                name of Oxya Origin Each secondary transaction (resell but not
                only) are subject to royalties of 10% plus no matter what the
                marketplace used for the transactions require. All product
                names, logos, and brands on this website are the property of
                their respective owners. All company, product and service names
                used in this website are for identification purposes only. Use
                of these names, logos, and brands does not imply endorsement.
                This site is not part of Facebook, or endorsed by, Facebook,
                Google, Snapchat, Twitter or any social media platform in any
                way. All product names, logos, and brands are the property of
                their respective owners. All company, product and service names
                used in this website are for identification purposes only. Use
                of these names, logos, and brands does not imply endorsement.
                FACEBOOK is a trademark of FACEBOOK, Inc. YOUTUBE and GOOGLE are
                trademarks of GOOGLE, LLC. SNAPCHAT is a trademark of SNAP, Inc.
                TWITTER is a trademark of TWITTER, Inc. Any reproduction,
                distribution or modification, partial or complete, of this
                website and related documents is strictly prohibited. This
                website and all its contents are protected by copyright and
                intellectual property laws for any country. The author reserves
                the right to claim damages if these terms are not respected.
              </p>
              <br />
            </Box>
          </Box>
        </Box>
      </section>
    </div>
  );
}

const useStyle = makeStyles(({ breakpoints }) => ({
  root: {
    overflowX: "hidden",
    fontSize: "1.4rem",
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    background: "rgb(7, 12, 25)",
  },
  section: {
    position: "relative",
    width: "100%",
    minHeight: "100vh",
  },
  menu: {
    maxWidth: "10rem",
    minWidth: "10rem",
    padding: "6rem 0",
    position: "fixed",
    height: "100%",
    zIndex: 2,
    background: "transparent",
    transform: "translate(0)",
    [breakpoints.down("xs")]: {
      display: "none",
    },
  },
  wrapperPage: {
    padding: "3rem",
    [breakpoints.up("sm")]: {
      paddingLeft: "13rem",
    },
    width: "100%",
    height: "100%",
  },
  containerContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    minHeight: "calc(100vh - 17rem)",
    "& h1": {
      marginTop: "3rem",
      textAlign: "center",
      width: "100%",
      fontFamily: "Akira",
      fontWeight: 600,
      textShadow: "0px 3px 9px rgba(0, 0, 0, 0.58)",
      lineHeight: 1.2,
      textTransform: "uppercase",
      whiteSpace: "pre-wrap",
      fontSize: "7rem",
      color: "white",
      [breakpoints.down("lg")]: {
        fontSize: "6rem",
      },
      [breakpoints.down("md")]: {
        fontSize: "4.8rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "3rem",
        textAlign: "center",
      },
    },
    "@media (max-height: 800px)": {
      marginTop: "4rem",
    },
  },
  wrapperText: {
    marginTop: "3rem",
    "& p": {
      textAlign: "justify",
    },
  },
}));

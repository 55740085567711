import React from "react";
import cx from "classnames";
import Proptypes from "prop-types";
import BlueBox from "../../atoms/BlueBox/blue-box";
import { Box, Typography, makeStyles } from "@material-ui/core";

export default function BoxChara({
  className,
  hrefPlanet,
  classNameTitle,
  color,
  colorTextShadow,
  classNameChildren,
  title,
  subTitle,
  children,
}) {
  const classes = useStyle({});
  return (
    <BlueBox className={cx(classes.root, className)}>
      <Box display="flex" gridRowGap="1rem" gridColumnGap="1rem">
        <img src={hrefPlanet} width={50} height={50} alt="planet kryo" />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className={classes.boxWrapperDetailsCharas}
        >
          <Typography
            variant="h3"
            style={{
              color: color,
              textShadow: `0 0 20px ${colorTextShadow}`,
            }}
            className={classNameTitle}
          >
            {title}
          </Typography>
          <Typography variant="body1" className={classes.subTitle}>
            {subTitle}
          </Typography>
        </Box>
      </Box>
      {children}
    </BlueBox>
  );
}

BoxChara.propTypes = {
  className: Proptypes.string,
  hrefPlanet: Proptypes.node,
  title: Proptypes.string,
  subTitle: Proptypes.string,
  children: Proptypes.node,
  classNameChildren: Proptypes.string,
  color: Proptypes.string,
  colorTextShadow: Proptypes.string,
};

const useStyle = makeStyles(() => ({
  root: {
    flexDirection: "column",
    padding: "5px",
    width: "fit-content",
    maxWidth: "320px",
    "& h3": {
      textTransform: "uppercase",
      fontSize: "2.2rem",
      fontWeight: 700,
      fontFamily: "Akira",
    },
  },
  boxWrapperDetailsCharas: {
    width: `calc(100% - 9rem)`,
  },
  subTitle: {
    fontSize: "1.4rem",
    lineHeight: 1,
    textAlign: "center",
  },
}));

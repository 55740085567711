import React from "react";
import Proptypes from "prop-types";
import useMeasure from "react-use-measure";
import { useSpring, animated } from "@react-spring/web";
import cx from "classnames";
import { IconButton, Typography, makeStyles, Box } from "@material-ui/core";

import ControlPointIcon from "@material-ui/icons/ControlPoint";
import RemoveIcon from "@material-ui/icons/Remove";

export default function ReadMore({
  className,
  classNameChildren,
  children,
  style,
  sx,
}) {
  const classes = useStyle({});
  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const [ref, bounds] = useMeasure();

  const panelContentAnimatedStyle = useSpring({
    height: isCollapsed ? 0 : bounds.height + 10,
    overflow: "hidden",
  });
  const togglePanel = () => {
    setIsCollapsed((prevState) => !prevState);
  };
  return (
    <Box className={cx(classes.root, className)} style={style} sx={sx}>
      <animated.div style={panelContentAnimatedStyle}>
        <Box ref={ref} className={classNameChildren}>
          {children}
        </Box>
      </animated.div>
      <Typography variant="body1" className={classes.readMore}>
        {isCollapsed ? "Read More" : "Read Less"}
      </Typography>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="read-more"
        onClick={togglePanel}
        className={classes.iconBtn}
      >
        {isCollapsed ? <ControlPointIcon /> : <RemoveIcon />}
      </IconButton>
    </Box>
  );
}

ReadMore.propTypes = {
  className: Proptypes.string,
  classNameChildren: Proptypes.string,
  children: Proptypes.node,
};

ReadMore.defaultProps = {
  children: null,
};

const useStyle = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "1rem",
    "& svg": {
      color: "white",
    },
  },
  readMore: {
    textAlign: "center",
    fontWeight: 600,
    color: "white",
    textTransform: "uppercase",
  },
  iconBtn: {
    margin: 0,
    padding: 0,
  },
}));

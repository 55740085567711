import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import imgSeoEcosystem from "../../assets/seo/ecosystem.jpg";

import cx from "classnames";

import { Box, Typography, makeStyles } from "@material-ui/core";

import bgEcosystem from "../../assets/backgrounds/ecosystem.jpg";

import Menu from "../organisms/Menu/menu";
import NavBar from "../organisms/NavBar/nav-bar";
import BlueBox from "../atoms/BlueBox/blue-box";
import { imgEcosystem } from "../../constants/image";
import ButtonWithIcon from "../molecules/ButtonWithIcon/button-with-icon";

import iconOpensea from "../../assets/social/opensea.svg";
import circleVideo from "../../assets/ecosystem/circle.svg";
import ButtonPerso from "../atoms/ButtonPerso/button-perso";

export default function Ecosystem() {
  const classes = useStyle({});

  const [ecosystemSelected, setEcosystemSelected] = React.useState(
    imgEcosystem[0]
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const handleClickNavigation = (item) => () => {
    setEcosystemSelected(item);
  };
  return (
    <div className={classes.root}>
      <Helmet>
        <title>Oxya Origin Ecosystem</title>
        <meta name="title" content="https://oxyaorigin.com/ecosystem" />
        <meta
          name="description"
          content="The Oxya Origin ecosystem consists of synergetic Avatars, Lands and Game Assets, Skins and Tokens in perpetual circular flow."
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://oxyaorigin.com/ecosystem" />
        <meta property="og:title" content="Oxya Origin Ecosystem" />
        <meta
          property="og:description"
          content="The Oxya Origin ecosystem consists of synergetic Avatars, Lands and Game Assets, Skins and Tokens in perpetual circular flow."
        />
        <meta property="og:image" content={imgSeoEcosystem} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta
          property="twitter:url"
          content="https://oxyaorigin.com/ecosystem"
        />
        <meta property="twitter:title" content="Oxya Origin Ecosystem" />
        <meta
          property="twitter:description"
          content="The Oxya Origin ecosystem consists of synergetic Avatars, Lands and Game Assets, Skins and Tokens in perpetual circular flow."
        />
        <meta property="twitter:image" content={imgSeoEcosystem} />
      </Helmet>
      <section className={classes.section}>
        <Menu
          className={cx(classes.menu, classes.translate0)}
          canBeVisible
          noWait
        />
        <Box className={classes.container}>
          <Box className={classes.wrapperPage}>
            <NavBar className={classes.translate0} />
            <Box className={classes.containerContent}>
              <Typography variant="h1">{ecosystemSelected.title}</Typography>
              <BlueBox className={classes.blueBox}>
                <Box className={classes.containerVideo}>
                  <Box className={classes.wrapperVideo}>
                    <video
                      src={ecosystemSelected.video}
                      autoPlay
                      playsInline
                      loop
                      muted
                    />
                  </Box>
                </Box>
                <Box className={classes.content}>
                  <Box className={classes.navigation}>
                    {imgEcosystem.map((ecosystem) => (
                      <button
                        type="button"
                        key={`ecosystem-${ecosystem.key}`}
                        className={cx(
                          classes.btnItemNavigation,
                          ecosystemSelected.title === ecosystem.title
                            ? classes.btnItemNavigationSelected
                            : "",
                          ecosystemSelected.title === ecosystem.title &&
                            ecosystemSelected.title.length > 7
                            ? classes.btnItemNavigationSelectedBig
                            : ""
                        )}
                        onClick={handleClickNavigation(ecosystem)}
                      >
                        <Box className={classes.wrapperItemNavigation}>
                          <img src={ecosystem.img} alt={ecosystem.title} />
                          <Box className={classes.wrapperItemText}>
                            <Typography
                              variant="body1"
                              className={classes.titleNavigation}
                            >
                              {ecosystem.title}
                            </Typography>
                            {!!ecosystem.subTitle && (
                              <Typography
                                variant="body2"
                                className={classes.subTitleNavigation}
                              >
                                {ecosystem.subTitle}
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      </button>
                    ))}
                  </Box>
                  <Box className={classes.containerDetailsSelected}>
                    <Typography
                      variant="body1"
                      className={classes.textDescription}
                    >
                      {ecosystemSelected.description}
                    </Typography>
                    <Box
                      display="flex"
                      justifyContent="center"
                      gridColumnGap="3rem"
                      gridRowGap="3rem"
                      className={classes.wrapperBtnDetails}
                    >
                      <ButtonPerso
                        onClick={ecosystemSelected.onClickBtnOne}
                        disabled={ecosystemSelected.disabledBtnOne}
                      >
                        {ecosystemSelected.textBtnOne}
                      </ButtonPerso>
                      <ButtonPerso
                        onClick={ecosystemSelected.onClickBtnTwo}
                        disabled={ecosystemSelected.disabledBtnTwo}
                      >
                        {ecosystemSelected.textBtnTwo}
                      </ButtonPerso>
                    </Box>
                  </Box>
                </Box>
              </BlueBox>
            </Box>
          </Box>
        </Box>
      </section>
    </div>
  );
}

const useStyle = makeStyles(({ breakpoints, palette }) => ({
  "@keyframes circle-moving": {
    "0%": {
      transform: "scale3d(0.985, 0.985, 1) rotate(0deg)",
    },
    "50%": {
      transform: "scale3d(1, 1, 1) rotate(180deg)",
    },
    "100%": {
      transform: "scale3d(0.985, 0.985, 1) rotate(360deg)",
    },
  },
  "@keyframes circle-moving-inverse": {
    "0%": {
      transform: "scale3d(0.985, 0.985, 1) rotate(0deg)",
    },
    "50%": {
      transform: "scale3d(1, 1, 1) rotate(-180deg)",
    },
    "100%": {
      transform: "scale3d(0.985, 0.985, 1) rotate(-360deg)",
    },
  },
  root: {
    overflowX: "hidden",
    fontSize: "1.4rem",
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    background: "rgb(7, 12, 25)",
  },
  section: {
    position: "relative",
    width: "100%",
    minHeight: "100vh",
    background: "rgba(7,12,25,0.2)",
  },
  translate0: {
    transform: "translate(0) !important",
    opacity: "1 !important",
    position: "relative",
    zIndex: 4,
  },
  menu: {
    maxWidth: "10rem",
    minWidth: "10rem",
    padding: "6rem 0",
    position: "fixed",
    height: "100%",
    zIndex: 2,
    background: "rgb(7, 12, 25)",
    [breakpoints.down("xs")]: {
      display: "none",
    },
  },
  container: {
    backgroundImage: `url(${bgEcosystem})`,
    backgroundPosition: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    minHeight: "100vh",
    [breakpoints.up("sm")]: {
      paddingLeft: "10rem",
    },
  },
  wrapperPage: {
    padding: "3rem",
    width: "100%",
  },
  containerContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    justifyContent: "center",
    marginTop: "10rem",
    minHeight: "calc(100vh - 27rem)",
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "140rem",
    [breakpoints.down("sm")]: {
      alignItems: "center",
      marginTop: 0,
    },
    "& h1": {
      fontFamily: "Akira",
      fontWeight: 600,
      textShadow: "0px 3px 9px rgba(0, 0, 0, 0.58)",
      lineHeight: 1.2,
      textTransform: "uppercase",
      whiteSpace: "pre-wrap",
      fontSize: "6rem",
      color: "white",
      zIndex: 5,
      [breakpoints.down("lg")]: {
        fontSize: "5rem",
      },
      [breakpoints.down("md")]: {
        fontSize: "4rem",
      },
      [breakpoints.down("sm")]: {
        display: "none",
      },
      marginLeft: "3rem",
    },
  },
  blueBox: {
    position: "relative",
    height: "auto",
    [breakpoints.down("sm")]: {
      marginTop: "20rem",
    },
  },
  content: {
    position: "relative",
    display: "flex",
    // padding: "4rem",
    [breakpoints.down("sm")]: {
      flexDirection: "column",
      marginTop: "-10rem",
    },
  },
  containerVideo: {
    position: "absolute",
    left: "50%",
    top: "-20rem",
    maxWidth: "40rem",
    backgroundImage: `url(${circleVideo})`,
    backgroundRepeat: "no-repeat",
    padding: "0.7rem",
    backgroundSize: "contain",
    animation:
      "$circle-moving 180s linear 0s infinite normal forwards running ",
    [breakpoints.down("sm")]: {
      position: "relative",
      left: 0,
      top: "-15rem",
      margin: "auto",
      maxWidth: "30rem",
    },
  },
  wrapperVideo: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    borderRadius: "50%",
    padding: "2px",
    [breakpoints.down("sm")]: {
      padding: 0,
    },
    "& video": {
      width: "374px",
      height: "374px",
      borderRadius: "50%",
      padding: "2px",
      transform: "rotate(0deg)",
      background: "linear-gradient(to right,#3a34cf,#50b6f6 60%)",
      animation:
        "$circle-moving-inverse 180s linear 0s infinite normal forwards running ",
      [breakpoints.down("sm")]: {
        width: "286px",
        height: "286px",
      },
    },
  },
  navigation: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    margin: "4rem 0",
    [breakpoints.down("sm")]: {
      flexDirection: "row",
      gap: 0,
      margin: 0,
      justifyContent: "space-around",
    },
  },
  btnItemNavigation: {
    border: "none",
    background: "none",
    padding: 0,
    height: "6rem",
    display: "flex",
    alignItems: "center",
    "& img": {
      transition: "0.2s all ease-in-out",
      width: "8rem",
      height: "8rem",
      border: `1px solid ${palette.primary.light}`,
      [breakpoints.down("sm")]: {
        width: "6rem",
        height: "6rem",
      },
    },
    "& p": {
      transition: "0.2s all ease-in-out",
    },
    [breakpoints.down("sm")]: {
      height: "initial",
    },
  },
  btnItemNavigationSelected: {
    background: palette.primary.light,
    minWidth: "35rem",
    "& img": {
      transform: "scale(1.2)",
    },
    "& p": {
      color: palette.primary.main,
      [breakpoints.down("sm")]: {
        fontSize: "1.6rem",
      },
    },
    [breakpoints.down("sm")]: {
      minWidth: "initial",
      padding: "0 1rem 1rem 1rem",
    },
  },
  btnItemNavigationSelectedBig: {
    [breakpoints.down("sm")]: {
      minWidth: "initial",
      padding: "0 0 1rem 0",
    },
  },
  wrapperItemNavigation: {
    // padding: "4rem",
    display: "flex",
    marginLeft: "4rem",
    [breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "1rem",
      margin: 0,
    },
  },
  wrapperItemText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "2rem",
    [breakpoints.down("sm")]: {
      margin: 0,
    },
  },
  titleNavigation: {
    color: "white",
    fontFamily: "BankGothic",
    textTransform: "uppercase",
    fontSize: "1.6rem",
    lineHeight: "1rem",
    [breakpoints.down("sm")]: {
      textAlign: "center",
      fontSize: "1.4rem",
    },
    [breakpoints.down(500)]: {
      fontSize: "1.2rem",
    },
  },
  subTitleNavigation: {
    color: "white",
    fontWeight: 300,
    textAlign: "left",
    [breakpoints.down("sm")]: {
      textAlign: "center",
      display: "none",
    },
  },
  containerDetailsSelected: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    gap: "3rem",
    padding: "0 4rem 4rem 4rem",
    [breakpoints.down("sm")]: {
      padding: "3rem 2rem",
      gap: "3rem",
    },
  },
  textDescription: {
    textAlign: "center",
    fontSize: "1.6rem",
    marginTop: "24rem",
    [breakpoints.down("sm")]: {
      marginTop: 0,
    },
  },
  wrapperBtnDetails: {
    [breakpoints.down(500)]: {
      flexDirection: "column",
      gap: "2rem",
      alignItems: "center",
    },
    "& button": {
      width: "22rem",
      boxShadow: "none",
    },
  },
}));

import React from "react";
import cx from "classnames";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  makeStyles,
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Proptypes from "prop-types";

export default function AccordionBox({
  className,
  classNameDetails,
  classNameTypoSummary,
  classNamePanel,
  summary,
  expanded,
  onChange,
  children,
  defaultExpanded,
  variantSummary,
}) {
  const classes = useStyle();

  return (
    <Accordion
      expanded={expanded}
      onChange={onChange}
      className={cx(classes.container, className)}
      defaultExpanded={defaultExpanded}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon className={classes.icon} />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        className={cx(classes.panel, classNamePanel)}
      >
        <Typography
          variant={variantSummary}
          className={cx(classes.summary, classNameTypoSummary)}
        >
          {summary}
        </Typography>
      </AccordionSummary>
      <AccordionDetails className={cx(classes.detail, classNameDetails)}>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

AccordionBox.propTypes = {
  children: Proptypes.node,
  summary: Proptypes.oneOfType([Proptypes.string, Proptypes.node]),
  title: Proptypes.string,
  expanded: Proptypes.bool,
  onChange: Proptypes.func,
  className: Proptypes.string,
  classNameTypoSummary: Proptypes.string,
  classNamePanel: Proptypes.string,
  classNameDetails: Proptypes.string,
  defaultExpanded: Proptypes.bool,
  variantSummary: Proptypes.string,
};

AccordionBox.defaultProps = {
  defaultExpanded: false,
  variantSummary: "body1",
};

const useStyle = makeStyles((theme) => ({
  panel: {
    transition: "0.2s all ease-in-out",
    background: theme.palette.primary.main,
    borderTopLeftRadius: "2rem",
    borderTopRightRadius: "2rem",
    borderBottomLeftRadius: "2rem",
    borderBottomRightRadius: "2rem",
    color: theme.palette.primary.dark,
    "& svg": {
      fontSize: 25,
      color: "white",
    },
    "&.Mui-expanded": {
      //background: "#00324F",
      "& svg": {
        color: "white",
      },
      "& p": {
        color: "white",
      },
      "& h5": {
        color: "white",
      },
      "& h4": {
        color: "white",
      },
    },
    "&:hover": {
      "& svg": {
        color: "white",
      },
      "& p": {
        color: "white",
      },
      "& h5": {
        color: "white",
      },
      "& h4": {
        color: "white",
      },
    },
  },
  summary: {
    color: "white",
    textTransform: "uppercase",
    fontSize: "2rem",
    fontFamily: "Akira",
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.8rem",
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.6rem",
    },
  },
  container: {
    width: "100%",
    border: "1px solid #025e84",
    background: theme.palette.primary.main,
    borderTopLeftRadius: "2rem !important",
    borderTopRightRadius: "2rem !important",
    borderBottomLeftRadius: "2rem !important",
    borderBottomRightRadius: "2rem !important",
    boxShadow: "0 0 20px #27c6f72b",
    borderRadius: "2rem !important",

    "&.MuiAccordion-root.Mui-expanded": {
      margin: 0,
      borderRadius: "2rem",
    },
  },
  icon: {
    color: theme.palette.primary.dark,
  },
  detail: {
    // maxHeight: "40vh",
    overflowY: "scroll",
    background: "transparent",
    // border: "0.5px solid #00649E",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    paddingTop: 0,
  },
}));

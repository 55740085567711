import React from "react";
import cx from "classnames";
import Proptypes from "prop-types";
import { makeStyles, Box, Typography } from "@material-ui/core";
import iconController from "../../../assets/icons/controller.svg";
import ButtonPerso from "../../atoms/ButtonPerso/button-perso";
import { appUrl } from "../../../constants/text";

export default function ButtonPreRegister({ className, id }) {
  const classes = useStyle({});

  const handleClickDownload = () => {
    window.open(`${appUrl}`);
  };
  return (
    <ButtonPerso
      className={cx(classes.btnRegister, className)}
      withBorder={false}
      id={id}
      onClick={handleClickDownload}
    >
      <Box className={classes.wrapperImageBtnRegister}>
        <img src={iconController} width={33} height={33} alt="register" />
      </Box>
      <Box className={classes.wrapperTextBtnRegister}>
        <Typography variant="h3">Download</Typography>
        <Typography variant="body1">Game for the Pre-Alpha Version</Typography>
      </Box>
    </ButtonPerso>
  );
}

ButtonPreRegister.propTypes = {
  className: Proptypes.string,
  id: "btnRegister",
};

ButtonPreRegister.defaultProps = {};

const useStyle = makeStyles(({ palette }) => ({
  btnRegister: {
    borderRadius: "1rem",
    width: "100%",
    maxWidth: "40rem",
    minWidth: "30rem",
    height: "10rem !important",
    padding: "0",
    background: "transparent",
    justifyContent: "flex-start",
    border: "none",
    "&:hover": {
      "& h3": {
        color: palette.primary.light,
      },
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      borderRadius: "1rem",
      padding: "2px",
      background:
        "linear-gradient( 360deg, rgb(63,0,207) 0%, rgb(0,180,255) 100%)",
      "-webkit-mask":
        "linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0)",
      "-webkit-maskComposite": "destination-out",
    },
    "&::after": {
      backgroundColor: "#fff",
      content: '""',
      position: "absolute",
      bottom: 0,
      top: 0,
      left: 0,
      right: 0,
      zIndex: 0,
      borderRadius: "9999px",
      opacity: 0,
    },
    "& span": {
      display: "flex",
      height: "100%",
      width: "100%",
      alignItems: "center",
    },
  },
  wrapperImageBtnRegister: {
    flex: 1,
    height: "100%",
    background:
      "linear-gradient( 360deg, rgb(63,0,207) 0%, rgb(0,180,255) 100%)",
    border: "none",
    borderRadius: "1rem",
    padding: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: "10rem",
    maxWidth: "10rem",
    zIndex: 1,
    "& img": {
      width: "51px",
    },
  },
  wrapperTextBtnRegister: {
    flex: 5,
    zIndex: 2,
    "& h3": {
      fontWeight: 600,
      fontSize: "2.6rem",
      transition: "0.2s all ease-in-out",
    },
    "& p": {
      textTransform: "initial",
    },
  },
}));

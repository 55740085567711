import React from "react";
import Proptypes from "prop-types";
import cx from "classnames";

import { Box, Typography, makeStyles } from "@material-ui/core";
import BlueBox from "../../atoms/BlueBox/blue-box";
import borderLands from "../../../assets/lands/contour-planette.png";
import BoxDetailsPlanet from "../../molecules/BoxDetailsPlanet/box-details-planet";
import { sizeMobileForOurLands } from "../../../constants/size";

export default function DetailsOurLands({ className, landSelected }) {
  const classes = useStyle({
    landSelected,
  });
  return (
    <Box
      display="flex"
      justifyContent="space-around"
      className={cx(classes.root, className)}
    >
      <Box className={classes.containerDetailsLands}>
        <BlueBox className={classes.wrapperBoxDetailsLand}>
          <Typography variant="h3">{landSelected.title}</Typography>
          <Typography variant="body1" style={{ fontSize: "1.6rem" }}>
            {landSelected.detailsBox}
          </Typography>
        </BlueBox>
        <Box>
          <img src={borderLands} alt="borderLands" />
        </Box>
      </Box>
      <Box className={classes.wrapperBoxDetailsPlanet}>
        <img
          className={classes.imgPlanetDetails}
          alt="details planet"
          src={landSelected.imgLand}
        />
        <BoxDetailsPlanet
          className={classes.containerBoxDetailsPlanet}
          landSelected={landSelected}
        />
      </Box>
    </Box>
  );
}

DetailsOurLands.propTypes = {
  className: Proptypes.string,
  landSelected: Proptypes.object,
};

const useStyle = makeStyles(({ palette, breakpoints }) => ({
  root: {
    transition: "0.2s all ease-in-out",
    backgroundImage: (props) => `url(${props.landSelected.imgBg})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    [breakpoints.down(sizeMobileForOurLands)]: {
      backgroundImage: (props) => `url(${props.landSelected.imgBgMobile})`,
    },
  },
  containerDetailsLands: {
    display: "flex",
    height: "fit-content",
    position: "relative",
    top: "170px",
    left: "50px",
    "& img": {
      width: "50%",
    },
    [breakpoints.down(1400)]: {
      top: "150px",
      left: "35px",
    },
    [breakpoints.down(1300)]: {
      top: "120px",
      left: "20px",
    },
    [breakpoints.down("sm")]: {
      display: "none",
    },
  },
  wrapperBoxDetailsLand: {
    maxWidth: "19rem",
    padding: "2rem",
    "&::after": {
      content: "''",
      position: "absolute",
      left: "-3px",
      top: "17px",
      width: "7px",
      height: "34px",
      background: palette.primary.light,
      borderRadius: "3rem",
      filter: "drop-shadow( 1px 1px 10px rgb(15, 190, 255))",
    },
  },
  imgPlanetDetails: {
    maxWidth: "50rem",
    position: "relative",
    zIndex: 2,
    [breakpoints.down("sm")]: {
      maxWidth: "35rem",
      left: "50%",
    },
    [breakpoints.down(sizeMobileForOurLands)]: {
      top: "-5rem",
      left: "40%",
      maxWidth: "25rem",
    },
  },
  containerBoxDetailsPlanet: {
    position: "relative",
    top: "-15rem",
    maxWidth: "40rem",
    margin: "auto",
    padding: "10rem 2rem 2rem 2rem",
    height: "initial",
    "&::after": {
      content: "''",
      position: "absolute",
      left: "-3px",
      top: "102px",
      width: "7px",
      height: "58px",
      background: palette.primary.light,
      borderRadius: "3rem",
      filter: "drop-shadow( 1px 1px 10px rgb(15, 190, 255))",
    },
    [breakpoints.down("sm")]: {
      maxWidth: "60rem",
      padding: "2rem",
    },
    [breakpoints.down(sizeMobileForOurLands)]: {
      maxWidth: "40rem",
      margin: "0 2rem",
      "&::after": {
        top: "21px",
      },
    },
  },
}));

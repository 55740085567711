import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import cx from "classnames";

import { Box, Typography, makeStyles } from "@material-ui/core";
import Menu from "../organisms/Menu/menu";
import NavBar from "../organisms/NavBar/nav-bar";

import bgGaming from "../../assets/backgrounds/gaming.jpg";
import pnjWeapon from "../../assets/gaming/pnj-weapon.png";

import imgSeoGaming from "../../assets/seo/gaming.jpeg";

import Coverflow from "react-coverflow";

import { imgCarouselGaming } from "../../constants/image";
import bgItemSelected from "../../assets/gaming/pc-portable-presentation.png";

import BlueBox from "../atoms/BlueBox/blue-box";
import ButtonPreRegister from "../molecules/ButtonPreRegister/button-pre-register";
import arrowLeft from "../../assets/icons/fleche2.svg";
import arrowRight from "../../assets/icons/fleche.svg";
export default function Gaming() {
  const classes = useStyle({});
  const [itemCarousselSelected, setItemCarousselSelected] = React.useState(
    imgCarouselGaming[3]
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    handleFirstLeft();
  }, []);

  const handleFirstLeft = () => {
    const number = itemCarousselSelected.key;
    setItemCarousselSelected(imgCarouselGaming[number - 3]);
  };

  const handleClickLeft = () => {
    const number = itemCarousselSelected.key;
    setItemCarousselSelected(imgCarouselGaming[number - 1]);
  };

  const handleClickRight = () => {
    const number = itemCarousselSelected.key;
    setItemCarousselSelected(imgCarouselGaming[number + 1]);
  };

  /*const handleClickCarousel = (item) => () => {
    console.log(item);
    setItemCarousselSelected(item);
  };*/

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Road to Genesis</title>
        <meta name="title" content="Road to Genesis" />
        <meta
          name="description"
          content="One universe featuring multiple game modes designed for everyone to have fun and play."
        />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://oxyaorigin.com/gaming" />
        <meta property="og:title" content="Road to Genesis" />
        <meta
          property="og:description"
          content="One universe featuring multiple game modes designed for everyone to have fun and play."
        />
        <meta property="og:image" content={imgSeoGaming} />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://oxyaorigin.com/gaming" />
        <meta property="twitter:title" content="Road to Genesis" />
        <meta
          property="twitter:description"
          content="One universe featuring multiple game modes designed for everyone to have fun and play."
        />
        <meta property="twitter:image" content={imgSeoGaming} />
      </Helmet>
      <section className={classes.section}>
        <Menu className={cx(classes.menu)} canBeVisible noWait />
        <Box className={classes.container}>
          <Box className={classes.wrapperPage}>
            <NavBar className={classes.translate0} />
            <Typography variant="h1" className={classes.title}>
              {itemCarousselSelected.title}
            </Typography>
            <Box className={classes.containerContent}>
              <Box className={classes.wrapperArrows}>
                <button
                  type="button"
                  className={cx(classes.btnArrow, classes.btnArrowLeft)}
                >
                  {itemCarousselSelected.key !== 0 && (
                    <img
                      alt="arrow left"
                      src={arrowLeft}
                      onClick={handleClickLeft}
                    />
                  )}
                </button>

                <button
                  type="button"
                  className={cx(classes.btnArrow, classes.btnArrowRight)}
                >
                  {itemCarousselSelected.key !==
                    imgCarouselGaming.length - 1 && (
                    <img
                      alt="arrow right"
                      className={cx(classes.arrow, classes.arrowRight)}
                      src={arrowRight}
                      onClick={handleClickRight}
                    />
                  )}
                </button>
              </Box>
              <Box className={classes.carouselCoverflow}>
                <Coverflow
                  width={2000}
                  height={563}
                  displayQuantityOfSide={2}
                  enableHeading={false}
                  enableScroll={false}
                  active={itemCarousselSelected.key}
                  currentFigureScale={2.2}
                  otherFigureScale={0.7}
                  clickable={false}
                  media={{
                    "@media (min-width: 1000px)": {
                      width: "2000px",
                      height: "563px",
                    },
                    "@media (max-width: 1000px) and (min-width: 700px)": {
                      width: "1500px",
                      height: "430px",
                    },
                    "@media (min-width: 450px) and (max-width: 700px)": {
                      width: "1000px",
                      height: "430px",
                    },
                    "@media (max-width: 450px)": {
                      width: "750px",
                      height: "430px",
                    },
                  }}
                >
                  {imgCarouselGaming.map((elem) => (
                    <>
                      {elem.key === itemCarousselSelected.key ? (
                        <Box className={classes.boxItemSelect} id="itemSelect">
                          <img
                            src={bgItemSelected}
                            alt="background itemSelect"
                            id="background-item-select"
                          />
                          <img
                            key={elem.key}
                            src={elem.img}
                            alt={elem.alt}
                            id="img-item-select"
                          />
                        </Box>
                      ) : (
                        <img key={elem.key} src={elem.img} alt={elem.alt} />
                      )}
                    </>
                  ))}
                </Coverflow>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={cx(classes.wrapperPage, classes.bottomPage)}>
          <Box className={classes.wrapperJoin}>
            <BlueBox className={classes.containerJoin}>
              <Typography variant="h2">Play the Pre-Alpha</Typography>
              <Typography variant="body1" className={classes.textJoinBeta}>
                Enter the Kryo dungeon and destroy waves of enemies to earn
                awesome NFT and $OXYZ token rewards
              </Typography>
              <ButtonPreRegister />
            </BlueBox>
            <Box className={classes.wrapperImgPnj}>
              <img src={pnjWeapon} alt="pnj with weapon" />
            </Box>
          </Box>
        </Box>
      </section>
    </div>
  );
}

const useStyle = makeStyles(({ breakpoints, palette }) => ({
  root: {
    overflowX: "hidden",
    fontSize: "1.4rem",
    fontFamily: "Montserrat, sans-serif",
    color: "white",
    background: "rgb(7, 12, 25)",
  },
  section: {
    position: "relative",
    width: "100%",
    minHeight: "100vh",
  },
  menu: {
    maxWidth: "10rem",
    minWidth: "10rem",
    padding: "6rem 0",
    position: "fixed",
    height: "100%",
    zIndex: 2,
    background: "transparent",
    transform: "translate(0)",
    [breakpoints.down("xs")]: {
      display: "none",
    },
  },
  container: {
    backgroundImage: `url(${bgGaming})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    minHeight: "100vh",
  },
  translate0: {
    transform: "translate(0) !important",
    opacity: "1 !important",
    position: "relative",
    zIndex: 4,
  },
  wrapperPage: {
    padding: "3rem 3rem 0 3rem",
    [breakpoints.up("sm")]: {
      paddingLeft: "13rem",
    },
    width: "100%",
    height: "100%",
  },
  containerContent: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    position: "relative",
    minHeight: "calc(100vh - 15rem)",
    alignItems: "center",
    justifyContent: "space-evenly",
    [breakpoints.down(1000)]: {
      justifyContent: "space-evenly",
    },
    "& div": {
      background: "transparent",
    },
    "& figure": {
      pointerEvents: "none",
      cursor: "initial",
    },
    "& h2": {
      fontFamily: "Akira",
      fontWeight: 600,
      textShadow: "0px 3px 9px rgba(0, 0, 0, 0.58)",
      lineHeight: 1.2,
      textTransform: "uppercase",
      whiteSpace: "pre-wrap",
      fontSize: "7rem",
      color: "white",
      [breakpoints.down("lg")]: {
        fontSize: "6rem",
      },
      [breakpoints.down("md")]: {
        fontSize: "4.8rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "3rem",
        textAlign: "center",
      },
    },
  },
  title: {
    whiteSpace: "pre-wrap",
    textAlign: "center",
    textTransform: "uppercase",
    fontSize: "3rem",
    position: "relative",
    marginBottom: "2rem",
    "@media (min-height: 800px)": {
      [breakpoints.down(1000)]: {
        top: "10rem",
      },
      [breakpoints.down(700)]: {
        top: "18rem",
      },
      [breakpoints.down(450)]: {
        top: "22rem",
      },
    },
    "@media (min-height: 700px) and (max-height: 800px)": {
      [breakpoints.down(1000)]: {
        top: "5rem",
      },
      [breakpoints.down(700)]: {
        top: "10rem",
      },
      [breakpoints.down(450)]: {
        top: "15rem",
      },
    },
    "@media (min-height: 600px) and (max-height: 700px)": {
      [breakpoints.down(1000)]: {
        top: "5rem",
      },
      [breakpoints.down(700)]: {
        top: "10rem",
      },
      [breakpoints.down(450)]: {
        top: "15rem",
      },
    },
    "@media (max-height: 600px)": {
      marginTop: "2rem",
    },
  },
  carouselCoverflow: {
    background: "transparent",
    "& figure": {
      marginTop: "-5rem",
      boxShadow: "none",
      "-webkit-box-reflect": "revert",
      "& img": {
        filter: "blur(2px)",
        width: "100%",
        display: "block",
        boxShadow: "2px 2px 5px rgb(0 0 0 / 50%)",
      },
    },
    "& figure:has(div#itemSelect)": {
      marginTop: 0,
      "-webkit-box-reflect": "revert",
      boxShadow: "none",
      margin: "5px",
      "& img": {
        filter: "blur(0)",
      },
    },
    "& #itemSelect": {
      position: "relative",
      "& #background-item-select": {
        boxShadow: "none",
        zIndex: 10,
      },
      "& #img-item-select": {
        position: "absolute",
        zIndex: 10,
        top: "4px",
        left: "41px",
        width: "319px",
        height: "180px",
        boxShadow: "none",
        [breakpoints.down(1000)]: {
          top: "4px",
          left: "31px",
          width: "239px",
          height: "135px",
        },
        [breakpoints.down(700)]: {
          top: "2px",
          left: "20px",
          width: "160px",
          height: "90px",
        },
        [breakpoints.down(450)]: {
          top: "2px",
          left: "15px",
          width: "120px",
          height: "68px",
        },
      },
    },
  },
  boxImgCaroussel: {
    marginTop: "1rem",
    display: "flex",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  wrapperArrows: {
    display: "flex",
    position: "absolute",
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "90rem",
    height: "inherit",
    zIndex: 3,
    marginTop: "-4rem",
    [breakpoints.down(400)]: {
      width: "110%",
    },
  },
  btnArrow: {
    zIndex: 3,
    border: "none",
    background: "transparent",
    cursor: "initial",
    "& img": {
      width: "30px",
      cursor: "pointer",
      [breakpoints.down(700)]: {
        width: "22px",
      },
    },
  },
  bottomPage: {
    paddingTop: 0,
    marginTop: "-15vh",
    "@media (min-height: 800px)": {
      marginTop: "-20vh",
    },
    "@media (min-height: 500px) and (max-height: 800px)": {
      marginTop: "-10vh",
    },
    "@media (min-height: 500px) and (max-height: 800px) and (max-width: 850px)":
      {
        marginTop: "-25vh",
      },
  },
  wrapperJoin: {
    display: "flex",
    margin: "4rem auto",
    maxWidth: "100rem",
    position: "relative",
    minHeight: "492px",
    justifyContent: "center",
    alignItems: "center",
    [breakpoints.down(500)]: {
      margin: "4rem 0",
    },
  },
  containerJoin: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "3rem",
    gap: "2rem",
    "& h2": {
      color: "white",
      textTransform: "uppercase",
      fontSize: "3rem",
      fontFamily: "Akira",
      fontWeight: 600,
    },
  },
  textJoinBeta: {
    color: "white",
    textAlign: "center",
    fontSize: "1.6rem",
    maxWidth: "90%",
    [breakpoints.down(850)]: {
      fontSize: "1.4rem",
    },
  },
  wrapperImgPnj: {
    position: "absolute",
    top: 0,
    right: "-240px",
    zIndex: 4,
    "& img": {
      width: "60%",
    },
    [breakpoints.down(1200)]: {
      display: "none",
    },
  },
}));
